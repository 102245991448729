.arrange {
  display: flex;
  flex-direction: row;
}

@media (max-width: 375px) {
  .arrange {
    display: block;
  }
}
